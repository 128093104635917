[data-js-typing]::after {
  margin-left: .2em;
  content: "|";
  animation: blinkCursor 1.2s linear infinite;
}

@keyframes blinkCursor {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
