
.accordion-minimal {
  .accordion-button,
  .accordion-body {
    padding: .5rem 0;
  }
  .accordion-button {
    background-color: transparent;
  }
  .accordion-item {
    background-color: transparent;
  }
}
