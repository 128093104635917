/*
  These is where we include all required scss files
  Commented files are components that's not used by discoverized
*/

// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "variables";
@import "../../node_modules/bootstrap/scss/variables";
// @import "../../node_modules/bootstrap/scss/mixins";
@import "mixins";

// @import "variables-overrides";

@import "../../node_modules/bootstrap/scss/utilities";

@import "utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "form-check";
@import "../../node_modules/bootstrap/scss/buttons";
@import "buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
// @import "../../node_modules/bootstrap/scss/close";
@import "close";
// @import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
// @import "../../node_modules/bootstrap/scss/offcanvas";
// @import "../../node_modules/bootstrap/scss/placeholders";

// Propertize's Layout & components
@import "bg";
@import "collapse";
@import "overlay";
@import "header";
@import "form-options";
@import "form-searchbox";
@import "section";
@import "swiper";
@import "sticky";
@import "counter";
@import "leaflet";
@import "js_typing";
@import "expand";
@import "icon";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
@import "utilities-extras";
