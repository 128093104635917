// stylelint-disable declaration-no-important

.leaflet-popup-content {
  margin: 0 !important;
  font-size: 1rem;
  .card {
    margin: -1px;
  }
}

.leaflet-container .leaflet-pane .leaflet-popup-close-button {
  top: -10px;
  left: 50%;
  z-index: 20;
  width: 20px;
  height: 20px;
  padding: 0;
  margin-left: -10px;
  line-height: 18px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: $box-shadow-sm;
}

.leaflet-marker {
  color: $white;
  background-color: $primary;
  &::after {
    position: absolute;
    top: 100%;
    left: 50%;
    display: block;
    margin-left: -10px;
    content: "";
    border-top: 10px solid $primary;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
}
