
.counter {
  counter-reset: custom;
}

.counter-number {
  width: 30px;
  &::before {
    content: counter(custom) ".";
    counter-increment: custom;
  }
}
