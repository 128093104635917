// Variables
//


// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;


$primary:   #627afe !default;
$secondary:   $gray-600 !default;
$light:       $gray-100 !default;
$dark:      #000 !default;


$body-bg:                 $light !default;
$body-color:                $gray-900 !default;
$body-inverse-color:        $gray-300 !default;

$link-color:                $dark !default;
$link-decoration:           none !default;
$link-hover-color:          $primary !default;
$link-hover-decoration:     none !default;

$link-inverse-color:        $body-inverse-color !default;
$link-inverse-hover-color:  $primary !default;


// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   3.5 !default;


// Typography
//

$font-family-sans-serif:  "Nunito Sans", sans-serif !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .8 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  sm: $font-size-sm
) !default;
// scss-docs-end font-sizes

$headings-font-family:    "Rubik", sans-serif !default;

// Lead
$lead-font-size:              $font-size-base * 1.175 !default;
$lead-font-weight:            300 !default;


// Components
//

$component-active-bg:                   $gray-600 !default;


// Forms

$input-focus-border-color:              $gray-600 !default;


// Card
$card-border-width: 0 !default;


// Nav
$nav-tabs-link-active-bg:             $white !default;


// Accordion
$accordion-color:                         $secondary !default;
$accordion-bg:                            $white !default;
$accordion-border-width:                  0 !default;
$accordion-button-bg:                     $accordion-bg !default;
$accordion-button-active-bg:              transparent !default;
$accordion-button-active-color:           $dark !default;

$accordion-button-focus-box-shadow:       none !default;
